<template>
  <div v-if="team" class="p2 flex flex-column items-center highest-team">
    <div class="flex flex-column items-center content center">
      <div class="mb3 title">{{ $t('funTeamBidding.bidding.highest') }}</div>
      <div class="flex items-center name-wrapper mb3">
        <img
          class="mr1"
          v-if="avatarsPack && team.teamAvatar"
          :src="require(`@/assets/avatars/${avatarsPack}/${team.teamAvatar}.svg`)"
        />
        <div class="team">{{ team.teamName }}</div>
      </div>
      <div class="mb3 value">{{ team.value }}</div>
    </div>
    <div class="mb2 jackpot center">{{ $t('funTeamBidding.bidding.jackpot') }}: {{ jackpot }}</div>
    <div v-if="vaBanque" class="mb2 jackpot center">
      {{ $t('funTeamBidding.bidding.vaBanque') }}
    </div>
    <div v-if="timeLeft !== -1" class="time-left center">
      {{ $t('funTeamBidding.bidding.timeLeft') }}: {{ timeLeft }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FunTeamBiddingPhaseBiddingHighestTeam',
    props: {
      team: {
        type: Object,
        default: () => null
      },
      jackpot: {
        type: Number,
        default: 0
      },
      timeLeft: {
        type: [Number, String],
        default: null
      },
      vaBanque: {
        type: Boolean,
        default: false
      },
      avatarsPack: {
        type: String,
        default: 'mixed'
      }
    }
  };
</script>

<style scoped lang="scss">
  .highest-team {
    color: color(primary);
    background: white;
    border-radius: 12px;
    margin-bottom: global-vars(ui-default-measure3x);
    box-shadow: global-vars(ui-default-box-shadow--light);

    .content {
      padding-bottom: global-vars(ui-default-measure2x);
    }

    .title {
      @include font(16px, 400, 22px);

      @include mobile() {
        @include font(14px, 400, 18px);
        margin-bottom: global-vars(ui-default-measure);
      }
    }
    .team {
      @include font(22px, 600, 30px);

      @include mobile() {
        @include font(18px, 600, 22px);
      }
    }
    .value {
      @include font(40px, 600, 36px);

      @include mobile() {
        @include font(30px, 600, 30px);
        margin-bottom: global-vars(ui-default-measure);
      }
    }
    .jackpot {
      @include font(16px, 600, 30px);

      @include mobile() {
        margin-bottom: 4px;
      }
    }
    .time-left {
      @include font(14px);
    }

    .name-wrapper {
      @include mobile() {
        margin-bottom: global-vars(ui-default-measure2x);
      }

      img {
        width: global-vars(ui-default-measure5x);
        height: global-vars(ui-default-measure5x);

        @include mobile() {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
</style>
