
  import { ref, computed, defineComponent, onMounted, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import { openTriviaDatabaseCategories } from '@/helpers/funteambidding/initials';
  import {
    emptyFunTeamBiddingPlayer,
    emptyFunTeamBiddingGame
  } from '@/helpers/funteambidding/initials';
  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import useFirebaseFunTeamBidding from '@/composables/use-firebase-funteambidding';
  import { FunTeamBiddingCategory } from '@/interfaces/funteambidding';
  import { GAME_NAMES, GAME_NAMES_HUMANIZED } from '@/helpers';
  import { useI18n } from 'vue-i18n';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'FunTeamBiddingNewGame',
    components: { LanguageSelection, LogoSpinner },
    setup() {
      const { getCurrentUser, addAlertThatNewGameStarted } = useFirebase();
      const user = getCurrentUser();
      const { getCurrentCompany } = useFirebaseFunTeamBidding();

      const { push } = useRouter();
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const { t } = useI18n();

      const loading: Ref = ref(false);
      const gameId: Ref<string> = ref('');
      const newGameLoading: Ref<boolean> = ref(false);
      const name: Ref<string> = ref('');
      const nickname: Ref<string> = ref('');
      const strength: Ref<string> = ref('');
      const weakness: Ref<string> = ref('');
      const noOfQuestionsLeft: Ref<number> = ref(10);
      const creatorShouldPlayGame: Ref<boolean> = ref(true);
      const selectedCategories: Ref<FunTeamBiddingCategory[]> = ref([]);

      const estimatedDuration: ComputedRef = computed(() => ({
        threeTen: `${noOfQuestionsLeft.value * 2 + 10} - ${noOfQuestionsLeft.value * 3 + 10}`,
        elevenTwenty: `${noOfQuestionsLeft.value * 3 + 10} - ${noOfQuestionsLeft.value * 4 + 10}`
      }));

      const company: ComputedRef = computed(() => store.state.company);

      const userHasPermissionsToCreateGames: ComputedRef = computed(
        () => store.state.user?.roles?.gameCreator
      );

      const categoriesToSelectFrom: ComputedRef = computed(() =>
        store.state.company.funTeamBiddingCustomQuestions.length
          ? openTriviaDatabaseCategories
          : openTriviaDatabaseCategories.filter(cat => cat.id !== 'CUSTOM')
      );

      const checkIfCategorySelected = (id: string) => {
        return selectedCategories.value.some(cat => cat.id === id);
      };

      const buttonDisabled: ComputedRef = computed(() => {
        if (creatorShouldPlayGame.value) {
          return (
            !name.value ||
            !userHasPermissionsToCreateGames.value ||
            !selectedCategories.value.length
          );
        } else {
          return !userHasPermissionsToCreateGames.value || !selectedCategories.value.length;
        }
      });

      const handleToggleCategory = (category: FunTeamBiddingCategory) => {
        if (checkIfCategorySelected(category.id)) {
          selectedCategories.value = selectedCategories.value.filter(
            (c: FunTeamBiddingCategory) => c.id !== category.id
          );
        } else {
          selectedCategories.value = [...selectedCategories.value, category];
        }
      };

      onMounted(async () => {
        loading.value = true;

        try {
          await getCurrentCompany();
          await getCurrentUserRoles();

          selectedCategories.value = store.state.company.funTeamBiddingSettings.categories;

          if (
            !store.state.company ||
            !store.state.company.package ||
            !store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_BIDDING] ||
            store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_BIDDING] === 0
          ) {
            errorToast(
              t('errors.noPackageOrGames', {
                name: GAME_NAMES_HUMANIZED.FUN_TEAM_BIDDING
              })
            );
            return;
            // logic to help user buy new ones
          }
        } catch (e) {
          errorToast(e);
        } finally {
          loading.value = false;
        }
      });

      const getCurrentUserRoles = async () => {
        const response = await db
          .doc(`companies/${store.state.company.id}/users/${user?.email}`)
          .get()
          .then(doc => doc.data());

        if (!response?.roles?.gameCreator) {
          warningToast(t('warnings.noPermissionsToCreateGames'));
        }
        store.commit('setCurrentUserRoles', response?.roles);
      };

      const createNewGame = async (game: any) => {
        delete game.id;

        return await db
          .collection(`companies/${store.state.company.id}/funTeamBiddingGames`)
          .add({
            ...game,
            createdOn: Date.now()
          })
          .then(docRef => {
            return docRef.get();
          })
          .then(doc => {
            return doc.id;
          });
      };

      const removeOneGameFromPackage = async () => {
        await db.doc(`companies/${store.state.company.id}`).update({
          package: {
            ...store.state.company.package,
            gamesLeft: {
              ...store.state.company.package.gamesLeft,
              [GAME_NAMES.FUN_TEAM_BIDDING]:
                store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_BIDDING] - 1
            }
          }
        });
      };

      const addGame = async (): Promise<any> => {
        if ([name.value, nickname.value, strength.value, weakness.value].some(v => v.length > 20)) {
          warningToast(t('warnings.cantBeLongerThan', { value: 20 }));
          return;
        }

        if (
          selectedCategories.value.every(c => c.id === 'CUSTOM') &&
          noOfQuestionsLeft.value > store.state.company.funTeamBiddingCustomQuestions.length
        ) {
          warningToast(
            t('warnings.tooFewCustomQuestions', {
              selected: noOfQuestionsLeft.value,
              created: store.state.company.funTeamBiddingCustomQuestions.length
            })
          );
          return;
        }

        if (newGameLoading.value) {
          return;
        }

        newGameLoading.value = true;

        // so that we have newest data
        await getCurrentCompany();

        if (
          !store.state.company ||
          !store.state.company.package ||
          !store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_BIDDING] ||
          store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_BIDDING] === 0
        ) {
          errorToast(
            t('errors.noPackageOrGames', {
              name: GAME_NAMES_HUMANIZED.FUN_TEAM_BIDDING
            })
          );
          newGameLoading.value = false;
          return;
          // logic to help user buy new ones
        }

        const settings = store.state.company.funTeamBiddingSettings;

        const playerData = {
          ...emptyFunTeamBiddingPlayer,
          name: name.value,
          nickname: nickname.value,
          strength: strength.value,
          weakness: weakness.value,
          email: user?.email || null,
          id: user?.uid,
          admin: true
        };

        const newDocument = {
          ...emptyFunTeamBiddingGame,
          categories: selectedCategories.value,
          noOfQuestionsLeft: Number(noOfQuestionsLeft.value),
          players: creatorShouldPlayGame.value ? [playerData] : [],
          gameCreator: playerData,
          avatarsPack: store.state.company.avatars.name,
          teams: emptyFunTeamBiddingGame.teams.map((team, index) => ({
            ...team,
            money: settings.teamMoneyForStart,
            avatar: store.state.company.avatars.list[index]
          })),
          settings: {
            categories: selectedCategories.value,
            teamMoneyForStart: settings.teamMoneyForStart,
            moneyTakenForStartOfBidding: settings.moneyTakenForStartOfBidding,
            timeAfterBidInSeconds: settings.timeAfterBidInSeconds,
            maxDebt: settings.maxDebt
          },
          customQuestions: store.state.company.funTeamBiddingCustomQuestions
        };

        try {
          gameId.value = await createNewGame(newDocument);
          await removeOneGameFromPackage();
          await addAlertThatNewGameStarted(
            'FunTeamBidding',
            store.state.company.id,
            store.state.company.name,
            gameId.value
          );
          successToast(t('success.gameCreated'));
          store.commit('toggleSound', true);
          newGameLoading.value = false;
          goToGame();
        } catch (e) {
          newGameLoading.value = false;
          errorToast(e);
        }
      };

      const goToGame = (): void => {
        push({
          name: 'funteambidding-game',
          params: { companyId: company.value.id, gameId: gameId.value }
        });
      };

      return {
        loading,
        name,
        user,
        newGameLoading,
        userHasPermissionsToCreateGames,
        company,
        nickname,
        strength,
        weakness,
        creatorShouldPlayGame,
        estimatedDuration,
        checkIfCategorySelected,
        addGame,
        handleToggleCategory,
        categoriesToSelectFrom,
        noOfQuestionsLeft,
        buttonDisabled
      };
    }
  });
