<template>
  <div
    v-if="peopleStillJoining.length"
    class="people-joining"
    :class="{ 'people-joining--hidden': !open }"
  >
    <b>{{ $t('funTeamBidding.peopleStillJoining') }}</b>
    <img
      class="people-joining__toggle"
      :class="{ 'people-joining__toggle--hidden': !open }"
      :src="require(`@/assets/images/chevron-down.svg`)"
      alt="Chevron"
      @click="open = !open"
    />
    <div class="mt2">
      <div
        v-for="player in peopleStillJoining"
        :key="player.id"
        class="flex items-center people-joining__item"
      >
        {{ player.name }}
        <span v-if="player.nickname" class="people-joining__nickname">
          {{ player.nickname }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PeopleStillJoining',
    data() {
      return {
        open: true
      };
    },
    props: {
      players: {
        type: Array,
        default: () => []
      },
      teams: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      peopleStillJoining() {
        const playersInTeams = this.teams.flatMap(team => team.players.map(p => p.id));

        return this.players.filter(p => !playersInTeams.includes(p.id));
      }
    }
  };
</script>

<style scoped lang="scss">
  .people-joining {
    position: fixed;
    left: 0;
    top: 100px;
    width: 250px;
    max-height: calc(100vh - 180px);
    min-height: 150px;
    border-radius: 0 20px 20px 0;
    padding: global-vars(ui-default-measure2x);
    background: white;
    box-shadow: global-vars(ui-default-box-shadow--dark);
    z-index: 1;
    transition: 0.4s ease;

    &--hidden {
      transform: translateX(-234px);
    }

    &__item {
      border-bottom: 1px solid color(gray-background);
      padding: 4px 0;
    }

    &__nickname {
      @include font(11px, 600);
      background: color(gray-background);
      color: color(primary);
      margin-left: global-vars(ui-default-measure);
      padding: 6px global-vars(ui-default-measure);
      border-radius: 10px;
    }

    &__toggle {
      position: absolute;
      top: -24px;
      right: -24px;
      transform: rotate(90deg);
      width: global-vars(ui-default-measure5x);
      height: global-vars(ui-default-measure5x);
      background: white;
      box-shadow: global-vars(ui-default-box-shadow--dark);
      padding: 10px;
      border-radius: 50%;

      &--hidden {
        transform: rotate(270deg);
      }
    }
  }
</style>
