<template>
  <div class="history">
    <div class="title mb2 color-white">
      {{ $t('funTeamBidding.bidding.history') }}
    </div>
    <div v-for="item in history" :key="item" class="py1 px2 flex items-center justify-between item">
      <img
        v-if="avatarsPack && item.teamAvatar"
        :src="require(`@/assets/avatars/${avatarsPack}/${item.teamAvatar}.svg`)"
      />
      <div class="team mr2 p1">{{ item.teamName }}</div>
      <div class="value">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FunTeamBiddingPhaseBiddingHistory',
    props: {
      avatarsPack: {
        type: String,
        default: 'mixed'
      },
      history: {
        type: Array,
        default: () => []
      }
    }
  };
</script>

<style scoped lang="scss">
  .history {
    margin-left: 30px;
    width: 170px;
    max-height: 65vh;
    overflow-y: auto;

    @include xl-up {
      width: 250px;
    }

    @include lg-down() {
      margin-left: 20px;
      width: 160px;
    }

    .item {
      background: white;
      padding: 4px 8px;
      border-radius: global-vars(ui-default-measure3x);
      margin: 8px 0;
      box-shadow: global-vars(ui-default-box-shadow--light);
      color: color(primary);

      img {
        width: 20px;
        height: 20px;
      }
    }

    .title {
      @include font(14px, 600);
      margin-bottom: global-vars(ui-default-measure2x);
    }

    .team {
      @include font(13px, 600);

      @include md-down() {
        @include font(10px, 600);

        margin-right: global-vars(ui-default-measure);
      }
    }

    .value {
      @include font(14px, 600);

      @include md-down() {
        @include font(10px, 600);
      }
    }
  }
</style>
