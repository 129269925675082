
  import { defineComponent, PropType, ref, watch } from 'vue';
  import { FunTeamBiddingGame } from '@/interfaces/funteambidding';
  import useFirebaseFunTeamBidding from '@/composables/use-firebase-funteambidding';

  export default defineComponent({
    name: 'FunTeamBiddingPhaseAnswer',
    props: {
      game: {
        type: Object as PropType<FunTeamBiddingGame>,
        default: () => ({})
      }
    },
    setup(props) {
      const {
        selectAnswer,
        currentUserIsCaptain,
        isCurrentPlayerInAnsweringTeam,
        highestBidder,
        jackpot
      } = useFirebaseFunTeamBidding();

      const selectedOption = ref('');
      const selectedOptionValid = ref(true);
      const validOption = ref('');

      const checkAnswer = (option: string) => {
        selectedOption.value = option;
        validOption.value = props.game.activeQuestion.validAnswer;
        selectedOptionValid.value = option === props.game.activeQuestion.validAnswer;
      };

      watch(
        () => props.game.answered,
        newVal => {
          if (newVal && newVal.length) {
            selectedOption.value = newVal;
            validOption.value = props.game.activeQuestion.validAnswer;
            selectedOptionValid.value = newVal === props.game.activeQuestion.validAnswer;
          }
        }
      );

      const handleSelectAnswer = async (option: string) => {
        if (isCurrentPlayerInAnsweringTeam.value && currentUserIsCaptain) {
          checkAnswer(option);
          await selectAnswer(selectedOptionValid.value, option);
        }
      };

      return {
        highestBidder,
        handleSelectAnswer,
        isCurrentPlayerInAnsweringTeam,
        selectedOption,
        selectedOptionValid,
        validOption,
        jackpot,
        currentUserIsCaptain
      };
    }
  });
