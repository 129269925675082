import { useStore } from 'vuex';
import firebase from 'firebase';
import { computed, ComputedRef } from 'vue';
import { emptyFunTeamBiddingPlayer, createTeam } from '@/helpers/funteambidding/initials';
import { Player } from '@/interfaces/businessgame';
import {
  FunTeamBiddingItem,
  FunTeamBiddingPlayer,
  FunTeamBiddingTeam
} from '@/interfaces/funteambidding';
import axios from 'axios';
import { avatarList } from '@/constants/avatars';
import { GAME_NAMES } from '@/helpers';
import { useRouter } from 'vue-router';
import useToast from './use-toast';
import { useI18n } from 'vue-i18n';

interface JoinGameInputData {
  name: string;
  nickname: string;
  strength: string;
  weakness: string;
}

export default function () {
  const store = useStore();
  const db = firebase.firestore();
  const { push } = useRouter();
  const { infoToast } = useToast();
  const { t } = useI18n();

  const user = computed(() => store.state.user);

  const getCurrentCompany = async (companyId: string | string[] | null = null) => {
    let company;

    if (companyId) {
      company = await db
        .doc(`companies/${companyId}`)
        .get()
        .then(doc => {
          return { id: doc.id, ...doc.data() };
        });
    } else {
      company = await db
        .collection('companies')
        .where('userEmails', 'array-contains', user.value?.email)
        .get()
        .then((querySnapshot: any) => {
          const resultArray: any = [];

          querySnapshot.forEach((doc: any) => {
            resultArray.push({ id: doc.id, ...doc.data() });
          });

          return resultArray[0];
        });
    }

    document.documentElement.style.setProperty('--color-accent', `${company.brandColor}`);

    store.commit('setCompany', company);
  };

  const getOneFunTeamBiddingGameAndListen = async (gameId: string) => {
    try {
      await db
        .collection(`companies/${store.state.company.id}/funTeamBiddingGames`)
        .doc(gameId)
        .onSnapshot(doc => {
          store.commit('setCurrentFunTeamBiddingGame', {
            ...doc.data(),
            id: doc.id
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getOneFunTeamBiddingGame = async (gameId: string) => {
    return await db
      .collection(`companies/${store.state.company.id}/funTeamBiddingGames`)
      .doc(gameId)
      .get()
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      });
  };

  const updateGame = async (game: any) => {
    const docRef = await db
      .collection(`companies/${store.state.company.id}/funTeamBiddingGames`)
      .doc(store.state.currentFunTeamBiddingGame.id);

    return await docRef
      .update({
        ...game,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        return docRef.get();
      })
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      });
  };

  const showJoinGame = computed(() => {
    if (!user.value?.id) {
      return true;
    }

    if (store.state.currentFunTeamBiddingGame.gameCreator?.id === user.value?.id) {
      return false;
    }

    return Boolean(
      store.state.currentFunTeamBiddingGame &&
        store.state.currentFunTeamBiddingGame.players &&
        user.value?.id &&
        !store.state.currentFunTeamBiddingGame.players.find(
          (player: Player) => player.id === user.value?.id
        )
    );
  });

  const showStartGameButton = computed(
    () =>
      store.state.currentFunTeamBiddingGame &&
      !store.state.currentFunTeamBiddingGame.gameStarted &&
      !store.state.currentFunIceBreakerGame.gameCancelled &&
      store.state.currentFunTeamBiddingGame.players &&
      store.state.currentFunTeamBiddingGame.players.length > 1 &&
      user &&
      store.state.currentFunTeamBiddingGame.players.find(
        (player: FunTeamBiddingPlayer) => player.admin
      ).id === user.value?.id &&
      store.state.currentFunTeamBiddingGame.teams.filter(
        (t: FunTeamBiddingTeam) => t.players.length > 0
      ).length > 1
  );

  const currentPlayer: ComputedRef<FunTeamBiddingPlayer> = computed(() => {
    return store.state.currentFunTeamBiddingGame.players.find(
      (player: FunTeamBiddingPlayer) => player.id === user.value?.id
    );
  });

  const sortedBiddingHistory = computed(() => {
    return [...store.state.currentFunTeamBiddingGame.bidding].sort(
      (a: FunTeamBiddingItem, b: FunTeamBiddingItem) => b.value - a.value
    );
  });

  const highestBidder = computed(() => {
    return sortedBiddingHistory.value[0];
  });

  const currentUserTeam: ComputedRef<FunTeamBiddingTeam> = computed(() => {
    return store.state.currentFunTeamBiddingGame.teams.find((team: FunTeamBiddingTeam) =>
      team.players.find((player: FunTeamBiddingPlayer) => player.id === user.value?.id)
    );
  });

  const jackpot: ComputedRef<number> = computed(() => {
    if (!sortedBiddingHistory.value.length) {
      return 0;
    }

    const usedTeamNames: string[] = [];

    const currentBiddingJackpot = sortedBiddingHistory.value.reduce((acc, next) => {
      if (!usedTeamNames.includes(next.teamName)) {
        acc += next.value;
        usedTeamNames.push(next.teamName);
        return acc;
      } else {
        return acc;
      }
    }, 0);

    const multiplier = store.state.currentFunTeamBiddingGame.vaBanqueTeam ? 2 : 1;

    return currentBiddingJackpot * multiplier + store.state.currentFunTeamBiddingGame.jackpot;
  });

  const isCurrentPlayerInAnsweringTeam = computed(() => {
    const playerTeam = store.state.currentFunTeamBiddingGame.teams.find(
      (team: FunTeamBiddingTeam) =>
        team.players.find((player: FunTeamBiddingPlayer) => player.id === user.value?.id)
    );

    return playerTeam?.name === store.state.currentFunTeamBiddingGame.answeringTeamName;
  });

  const currentUserIsCaptain = computed(() => {
    const allPlayersInTeams = store.state.currentFunTeamBiddingGame.teams.flatMap(
      (t: FunTeamBiddingTeam) => t.players
    );
    return allPlayersInTeams.some((player: FunTeamBiddingPlayer) => {
      return player.id === user.value.id && player.isCaptain;
    });
  });

  const joinGame = async (data: JoinGameInputData) => {
    if (!user.value?.id) {
      await firebase.auth().signInAnonymously();
    }

    await updateGame({
      players: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            ...emptyFunTeamBiddingPlayer,
            ...data,
            id: user.value?.id,
            email: user.value?.email,
            admin: !store.state.currentFunTeamBiddingGame.players.length
          }
        ]
      )
    });

    if (!store.state.currentFunTeamBiddingGame.players.length) {
      infoToast(t('newGame.firstPlayerToBeAdmin'), 6000);
      store.commit('toggleSound', true);
    }
  };

  const joinTeam = async (teams: FunTeamBiddingTeam[], teamId: string) => {
    const teamIndex = teams.findIndex(t => t.id === teamId);
    const team = teams.find(t => t.id === teamId) || { players: [] };

    await updateGame({
      teams: [
        ...store.state.currentFunTeamBiddingGame.teams.slice(0, teamIndex),
        {
          ...team,
          players: [
            ...team.players,
            {
              ...currentPlayer.value,
              isCaptain: !team.players.length
            }
          ]
        },
        ...store.state.currentFunTeamBiddingGame.teams.slice(teamIndex + 1)
      ]
    });
  };

  const leaveTeam = async (teams: FunTeamBiddingTeam[], teamId: string) => {
    const teamIndex = teams.findIndex(t => t.id === teamId);
    const team = teams.find(t => t.id === teamId) || { players: [] };

    const filteredPlayers = team.players.filter(p => p.id !== currentPlayer.value.id);

    if (filteredPlayers.length > 0 && filteredPlayers.every(player => !player.isCaptain)) {
      filteredPlayers[0].isCaptain = true;
    }

    const updatedTeam = {
      ...team,
      players: filteredPlayers
    };

    await updateGame({
      teams: [
        ...store.state.currentFunTeamBiddingGame.teams.slice(0, teamIndex),
        updatedTeam,
        ...store.state.currentFunTeamBiddingGame.teams.slice(teamIndex + 1)
      ]
    });
  };

  const changeTeamName = async (teams: FunTeamBiddingTeam[], teamId: string, newName: string) => {
    const teamIndex = teams.findIndex(t => t.id === teamId);
    const team = teams.find(t => t.id === teamId) || { players: [] };

    await updateGame({
      teams: [
        ...store.state.currentFunTeamBiddingGame.teams.slice(0, teamIndex),
        {
          ...team,
          name: newName
        },
        ...store.state.currentFunTeamBiddingGame.teams.slice(teamIndex + 1)
      ]
    });
  };

  const changeCaptain = async (
    newCaptainId: string,
    teams: FunTeamBiddingTeam[],
    teamId: string
  ) => {
    const teamIndex: number = teams.findIndex(t => t.id === teamId);
    const team: FunTeamBiddingTeam | undefined = teams.find(t => t.id === teamId);

    await updateGame({
      teams: [
        ...store.state.currentFunTeamBiddingGame.teams.slice(0, teamIndex),
        {
          ...team,
          players: team?.players.map((p: FunTeamBiddingPlayer) => ({
            ...p,
            isCaptain: p.id === newCaptainId
          }))
        },
        ...store.state.currentFunTeamBiddingGame.teams.slice(teamIndex + 1)
      ]
    });
  };

  const deletePlayer = async (playerId: string, teams: FunTeamBiddingTeam[], teamId: string) => {
    const teamIndex: number = teams.findIndex(t => t.id === teamId);
    const team: FunTeamBiddingTeam | undefined = teams.find(t => t.id === teamId);

    const newPlayers = team?.players.filter((p: FunTeamBiddingPlayer) => p.id !== playerId);

    if (newPlayers && newPlayers.every(p => !p.isCaptain)) {
      newPlayers[0].isCaptain = true;
    }

    await updateGame({
      teams: [
        ...store.state.currentFunTeamBiddingGame.teams.slice(0, teamIndex),
        {
          ...team,
          players: newPlayers
        },
        ...store.state.currentFunTeamBiddingGame.teams.slice(teamIndex + 1)
      ],
      players: store.state.currentFunTeamBiddingGame.players.filter(
        (p: FunTeamBiddingPlayer) => p.id !== playerId
      )
    });
  };

  const addNewTeam = async () => {
    const currentTeamsLength = store.state.currentFunTeamBiddingGame.teams.length;

    const avatars = avatarList.find(
      list => list.name === store.state.currentFunTeamBiddingGame.avatarsPack
    );

    await updateGame({
      teams: firebase.firestore.FieldValue.arrayUnion(
        ...[
          createTeam(
            `${currentTeamsLength + 1}`,
            `Team ${currentTeamsLength + 1}`,
            store.state.company.funTeamBiddingSettings.teamMoneyForStart,
            avatars ? avatars.list[currentTeamsLength] : ''
          )
        ]
      )
    });
  };

  // backend requests
  const addBidding = async (biddingItem: FunTeamBiddingItem, vaBanque = false) => {
    const biddingTeamIndex = store.state.currentFunTeamBiddingGame.teams.findIndex(
      (team: FunTeamBiddingTeam) => team.name === biddingItem.teamName
    );
    const biddingTeam = store.state.currentFunTeamBiddingGame.teams[biddingTeamIndex];

    const currentHighestTeamBid = sortedBiddingHistory.value.find(
      (item: FunTeamBiddingItem) => item.teamName === biddingItem.teamName
    );

    return await axios
      .post(`/funteambidding/add-bidding`, {
        biddingItem,
        biddingTeamIndex,
        biddingTeam,
        currentHighestTeamBid,
        gameId: store.state.currentFunTeamBiddingGame.id,
        companyId: store.state.company.id,
        vaBanque
      })
      .then(() => null)
      .catch(e => console.log(e));
  };

  const selectAnswer = async (valid: boolean, answer: string) => {
    const answeringTeamIndex = store.state.currentFunTeamBiddingGame.teams.findIndex(
      (team: FunTeamBiddingTeam) =>
        team.name === store.state.currentFunTeamBiddingGame.answeringTeamName
    );
    const answeringTeam = store.state.currentFunTeamBiddingGame.teams[answeringTeamIndex];

    return await axios
      .post(`/funteambidding/answer-question`, {
        valid,
        answer,
        answeringTeamIndex,
        answeringTeam,
        jackpot: jackpot.value,
        gameId: store.state.currentFunTeamBiddingGame.id,
        companyId: store.state.company.id
      })
      .then(() => true)
      .catch(() => {
        throw new Error();
      });
  };

  const startGame = async () => {
    return await axios
      .post(`/funteambidding/start-game`, {
        gameId: store.state.currentFunTeamBiddingGame.id,
        companyId: store.state.company.id
      })
      .then(() => null)
      .catch(e => console.log(e));
  };

  const cancelGame = async () => {
    try {
      await db.doc(`companies/${store.state.company.id}`).update({
        package: {
          ...store.state.company.package,
          gamesLeft: {
            ...store.state.company.package.gamesLeft,
            [GAME_NAMES.FUN_TEAM_BIDDING]:
              store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_BIDDING] + 1
          }
        }
      });

      await db
        .collection(`companies/${store.state.company.id}/funTeamBiddingGames`)
        .doc(store.state.currentFunTeamBiddingGame.id)
        .update({
          gameCancelled: true
        });
    } catch (e) {
      console.log(e);
    }

    push('/dashboard');
  };

  const readyForQuestion = async () => {
    return await axios
      .post(`/funteambidding/ready-for-question`, {
        gameId: store.state.currentFunTeamBiddingGame.id,
        companyId: store.state.company.id,
        highestBidderName: highestBidder.value.teamName
      })
      .then(() => null)
      .catch(e => console.log(e));
  };

  const passFromBidding = async (teamName: string) => {
    return await axios
      .post(`/funteambidding/pass-from-bidding`, {
        gameId: store.state.currentFunTeamBiddingGame.id,
        companyId: store.state.company.id,
        teamName
      })
      .then(() => null)
      .catch(e => console.log(e));
  };

  const handleTakeLoan = async (team: FunTeamBiddingTeam, loan: number) => {
    const teamIndex = store.state.currentFunTeamBiddingGame.teams.findIndex(
      (t: FunTeamBiddingTeam) => t.name === team.name
    );

    return await axios
      .post(`/funteambidding/get-loan`, {
        gameId: store.state.currentFunTeamBiddingGame.id,
        companyId: store.state.company.id,
        teamIndex,
        team,
        loan
      })
      .then(() => null)
      .catch(e => console.log(e));
  };

  const handleRepayToBank = async (team: FunTeamBiddingTeam, repay: number) => {
    const teamIndex = store.state.currentFunTeamBiddingGame.teams.findIndex(
      (t: FunTeamBiddingTeam) => t.name === team.name
    );

    return await axios
      .post(`/funteambidding/repay-loan`, {
        gameId: store.state.currentFunTeamBiddingGame.id,
        companyId: store.state.company.id,
        teamIndex,
        team,
        repay
      })
      .then(() => null)
      .catch(e => console.log(e));
  };

  const addAdmin = async (id: string) => {
    const game = store.state.currentFunTeamBiddingGame;
    const playerIndex = game.players.findIndex((p: FunTeamBiddingPlayer) => p.id === id);

    await updateGame({
      id: game.id,
      players: [
        ...game.players.slice(0, playerIndex),
        {
          ...game.players[playerIndex],
          admin: true
        },
        ...game.players.slice(playerIndex + 1)
      ]
    });
  };

  return {
    showJoinGame,
    showStartGameButton,
    currentPlayer,
    sortedBiddingHistory,
    highestBidder,
    currentUserTeam,
    jackpot,
    isCurrentPlayerInAnsweringTeam,
    currentUserIsCaptain,
    changeCaptain,
    startGame,
    joinGame,
    joinTeam,
    leaveTeam,
    getOneFunTeamBiddingGame,
    updateGame,
    getOneFunTeamBiddingGameAndListen,
    getCurrentCompany,
    addBidding,
    selectAnswer,
    readyForQuestion,
    changeTeamName,
    addNewTeam,
    passFromBidding,
    handleTakeLoan,
    handleRepayToBank,
    cancelGame,
    deletePlayer,
    addAdmin
  };
}
