<template>
  <div class="flex flex-column items-center bidding-menu" ref="menu">
    <div class="flex">
      <div class="mb1 title">{{ $t('players.cash') }}: {{ team.money }}</div>
      <div class="mx1"></div>
      <div v-if="team.loan > 0" class="mb1 title">
        {{ $t('funTeamBidding.debt') }}: {{ team.loan }}
      </div>
    </div>
    <div class="mb2 center">
      {{ $t('funTeamBidding.bidding.captain') }}
    </div>
    <div v-if="teamPassed">
      {{ $t('funTeamBidding.bidding.youResigned') }}
    </div>
    <div class="flex" v-else>
      <div class="mx2 flex flex-column items-stretch bidding-menu__input">
        <div class="bidding-menu__title center mb2">
          {{ $t('funTeamBidding.bidding.auctionMenu') }}
        </div>
        <Input
          id="name"
          type="text"
          v-model="bid"
          class="input"
          :placeholder="$t('funTeamBidding.bidding.biddingPrice')"
          @keyup.enter="
            $emit('bid', bid);
            bid = '';
          "
        />
        <div class="mt1">
          <BaseButtonWithSpinner
            :loading="loading"
            :disabled="loading"
            @click="
              $emit('bid', bid);
              bid = '';
            "
          >
            {{ $t('funTeamBidding.bidding.bid') }}
          </BaseButtonWithSpinner>
        </div>
      </div>
      <div class="mx2 flex flex-column bidding-menu__quick-actions">
        <div class="bidding-menu__title center mb2">
          {{ $t('funTeamBidding.bidding.quickActions') }}
        </div>
        <div class="flex flex-wrap">
          <BaseButtonWithSpinner
            v-for="amount in [50, 100, 200]"
            :disabled="loading"
            :loading="loading"
            :key="amount"
            @click="$emit('quickBid', amount)"
          >
            +{{ amount }}
          </BaseButtonWithSpinner>
          <BaseButtonWithSpinner
            v-if="showConfig.canPass && !passed"
            :disabled="loading"
            :loading="loading"
            @click="
              $emit('pass');
              passed = true;
            "
          >
            {{ $t('funTeamBidding.bidding.resign') }}
          </BaseButtonWithSpinner>
          <BaseButtonWithSpinner
            v-if="showConfig.canPlayVaBanque"
            :disabled="!vaBanqueTimeEnabled"
            :loading="loading"
            @click="$emit('goVaBanque')"
          >
            {{ $t('funTeamBidding.bidding.playVaBanque') }}
            <br />
            <span v-if="timeToVaBanqueEnabled > 0" class="ml1">
              {{ $t('funTeamBidding.bidding.availableIn', { value: timeToVaBanqueEnabled }) }}
            </span>
          </BaseButtonWithSpinner>
        </div>
      </div>
      <div class="mx2 flex flex-column bidding-menu__bank">
        <div class="bidding-menu__title center mb2">
          {{ $t('funTeamBidding.bidding.bank') }}
        </div>
        <Input
          id="bank"
          type="number"
          v-model="loan"
          class="input"
          :placeholder="$t('funTeamBidding.bidding.loanValue')"
          @keyup.enter="
            $emit('loan', loan);
            loan = '';
          "
        />
        <div class="mt1">
          <BaseButtonWithSpinner
            :loading="loanLoading"
            :disabled="loanLoading"
            @click="
              $emit('loan', loan);
              loan = '';
            "
          >
            {{ $t('funTeamBidding.bidding.takeLoan') }}
          </BaseButtonWithSpinner>
        </div>
        <div class="my1 disclaimer center">
          {{ $t('funTeamBidding.bidding.interest', { maxDebt }) }}
        </div>
        <template v-if="team.loan > 0">
          <Input
            id="repay"
            type="number"
            v-model="repay"
            class="input"
            :placeholder="$t('funTeamBidding.bidding.repayValue')"
            @keyup.enter="
              $emit('repay', repay);
              repay = '';
            "
          />
          <div class="mt1">
            <BaseButtonWithSpinner
              :loading="repayLoading"
              :disabled="repayLoading"
              @click="
                $emit('repay', repay);
                repay = '';
              "
            >
              {{ $t('funTeamBidding.bidding.repay') }}
            </BaseButtonWithSpinner>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch, computed } from 'vue';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner';
  import { useStore } from 'vuex';

  export default {
    name: 'FunTeamBiddingPhaseBiddingMenu',
    components: { BaseButtonWithSpinner },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      loanLoading: {
        type: Boolean,
        default: false
      },
      repayLoading: {
        type: Boolean,
        default: false
      },
      timeIsUp: {
        type: Boolean,
        default: false
      },
      team: {
        type: Object,
        default: () => ({})
      },
      teamsPassedFromBidding: {
        type: Array,
        default: () => []
      },
      showConfig: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        bid: '',
        loan: '',
        repay: '',
        interval: null,
        passed: false
      };
    },
    computed: {
      teamPassed() {
        return this.teamsPassedFromBidding.includes(this.team.name);
      }
    },
    setup(props) {
      const menu = ref(null);
      const vaBanqueTimeEnabled = ref(false);
      const timeToVaBanqueEnabled = ref(10);
      const interval = ref(null);
      const store = useStore();

      watch(
        () => props.timeIsUp,
        newVal => {
          if (newVal) {
            menu.value?.classList.remove('show');
          }
        }
      );

      const maxDebt = computed(() => {
        return store.state.company.funTeamBiddingSettings.maxDebt;
      });

      const setVaBanqueInterval = () => {
        timeToVaBanqueEnabled.value = timeToVaBanqueEnabled.value - 1;

        if (timeToVaBanqueEnabled.value === 0) {
          vaBanqueTimeEnabled.value = true;
          clearInterval(interval.value);
        }
      };

      onMounted(() => {
        setTimeout(() => {
          menu.value?.classList.add('show');
        }, 100);

        interval.value = setInterval(() => setVaBanqueInterval(), 1000);
      });

      return { menu, maxDebt, vaBanqueTimeEnabled, timeToVaBanqueEnabled };
    }
  };
</script>

<style scoped lang="scss">
  .bidding-menu {
    padding: 24px 16px 40px 16px;
    border-radius: global-vars(ui-default-measure4x);
    margin: 8px 0;
    box-shadow: global-vars(ui-default-box-shadow--light);
    background: white;
    position: fixed;
    left: 50%;
    transform: translateX(calc(-50% - 300px));
    bottom: -100%;
    z-index: 9999;
    transition: bottom 1s ease-in-out;

    &.show {
      bottom: -32px;
    }

    @include lg-down() {
      transform: translateX(calc(-50% - 200px));
    }

    @include md-down() {
      transform: translateX(calc(-50% - 100px));
    }

    @include mobile() {
      transform: translateX(-50%);
    }

    .title {
      @include font(16px, 600);
    }

    .input {
      border-radius: global-vars(ui-default-measure3x);
      margin-bottom: global-vars(ui-default-measure);
    }

    &__input {
      width: 200px;

      @include mobile() {
        margin: 0 4px;
        width: 115px;
      }

      button {
        width: 100%;

        @include mobile() {
          @include font(12px, 600);
          padding: 6px 10px;
        }
      }
    }

    &__quick-actions {
      width: 200px;

      @include mobile() {
        margin: 0 4px;
        width: 115px;
      }

      button {
        margin: 4px;

        @include mobile() {
          @include font(12px, 600);
          padding: 6px 10px;
        }
      }
    }

    &__bank {
      width: 200px;

      @include mobile() {
        margin: 0 4px;
        width: 115px;
      }

      button {
        width: 100%;

        @include mobile() {
          @include font(12px, 600);
          padding: 6px 10px;
        }
      }
    }

    &__title {
      @include font(14px, 600);
      padding: 4px 8px;
      background: color(gray-background);
      border-radius: global-vars(ui-default-measure2x);

      @include mobile() {
        @include font(12px, 600);
      }
    }
  }

  .disclaimer {
    @include font(10px, 400);
  }
</style>
