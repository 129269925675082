<template>
  <div class="flex flex-column justify-between left-navigation__content-wrapper">
    <div
      class="flex flex-column left-navigation__content"
      :class="addPaddingToTeams && 'with-padding'"
    >
      <FunTeamBiddingTeams
        :game-creator="gameCreator"
        :teams="teams"
        :phase="phase"
        :avatars-pack="avatarsPack"
        :players="players"
      />
    </div>
    <div class="left-navigation__content-show-teams" @click="mobileActive = !mobileActive">
      <img :src="require(`@/assets/images/team.svg`)" alt="" />
      <div v-if="!mobileActive">{{ $t('funTeamBidding.teams.label') }}</div>
      <b v-else>{{ $t('common.hide') }}</b>
    </div>
    <div class="left-navigation__content--mobile" :class="{ active: mobileActive }">
      <FunTeamBiddingTeams
        :game-creator="gameCreator"
        :teams="teams"
        :phase="phase"
        :avatars-pack="avatarsPack"
        :players="players"
        :small="true"
      />
    </div>
  </div>
</template>

<script>
  import FunTeamBiddingTeams from '@/components/funteambidding/FunTeamBiddingTeams';

  export default {
    name: 'FunTeamBiddingLeftNavigation',
    components: {
      FunTeamBiddingTeams
    },
    props: {
      gameCreator: {
        type: Object,
        default: () => ({})
      },
      phase: {
        type: String,
        default: ''
      },
      activeCategory: {
        type: String,
        default: ''
      },
      avatarsPack: {
        type: String,
        default: 'mixed'
      },
      teams: {
        type: Array,
        default: () => []
      },
      players: {
        type: Array,
        default: () => []
      },
      addPaddingToTeams: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        mobileActive: false
      };
    }
  };
</script>

<style scoped lang="scss">
  .category {
    @include font(18px);
  }

  .left-navigation__content {
    height: 100%;

    @include mobile() {
      display: none;
    }

    &-wrapper {
      overflow-y: auto;
    }

    &.with-padding {
      padding-bottom: 300px;
      overflow-y: auto;
    }

    &--mobile {
      display: none;

      @include mobile() {
        display: block;
        background: color(accent);
        position: fixed;
        transform: translateX(-110%);
        height: 100%;
        width: 100%;
        transition: 0.5s ease;
        z-index: 999;
        padding-left: global-vars(ui-default-measure3x);
        top: 0;
      }

      &.active {
        transform: translateX(0);
      }
    }

    &-show-teams {
      display: none;

      @include mobile() {
        display: block;
        z-index: 1000;
        background: white;
        padding: global-vars(ui-default-measure);
        border-radius: 0 8px 8px 0;
        position: fixed;
        top: 12px;
        left: 0;
        @include font(14px, 600, 14px);
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
</style>
