<template>
  <div
    v-if="phase !== 'WELCOME'"
    class="flex items-center justify-between funteambidding__phase-nav"
  >
    <div class="funteambidding__phase-nav-phase">
      {{ $t(`funTeamBidding.phases.${FUN_TEAM_BIDDING_PHASES[phase].step - 1}`) }}
    </div>
    <div class="funteambidding__phase-nav-step">
      {{ $t('funTeamBidding.phaseOf.0') }}
      {{ FUN_TEAM_BIDDING_PHASES[phase].step }}
      {{ $t('funTeamBidding.phaseOf.1') }}
      {{ Object.values(FUN_TEAM_BIDDING_PHASES).length }}
    </div>
    <div class="ml2 funteambidding__phase-nav-questions-left">
      {{
        questionsLeft === 1
          ? $t('common.lastRound')
          : $t('funTeamBidding.questionsLeft', { value: questionsLeft })
      }}
    </div>
  </div>
</template>

<script>
  import { FUN_TEAM_BIDDING_PHASES } from '@/helpers/funteambidding/initials';

  export default {
    name: 'FunTeamBiddingPhaseNav',
    props: {
      phase: {
        type: String,
        default: ''
      },
      questionsLeft: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        FUN_TEAM_BIDDING_PHASES
      };
    }
  };
</script>

<style scoped lang="scss">
  .funteambidding__phase-nav {
    padding: 12px 24px;
    background: white;
    box-shadow: global-vars(ui-default-box-shadow--light);
    border-radius: global-vars(ui-default-measure3x);

    @include mobile() {
      width: calc(100% - 116px);
      margin-left: 70px;
    }

    &-phase {
      flex: 1;
      @include font(20px, 600);
      margin-right: 20px;

      @include lg-down() {
        @include font(17px, 600);
      }

      @include md-down() {
        @include font(15px, 600);
      }
    }
    &-step {
      @include font(14px, 600);

      @include lg-down() {
        @include font(13px, 600);
      }

      @include md-down() {
        @include font(12px, 600);
      }
    }
    &-questions-left {
      @include font(13px, 600);

      @include lg-down() {
        @include font(12px, 600);
      }

      @include md-down() {
        @include font(9px, 600);
      }
    }
  }
</style>
