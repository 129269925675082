<template>
  <div class="category color-white">
    {{ $t('funTeamBidding.selectedCategory') }} -
    <b class="ml1">{{ $t(`funTeamBidding.categories.${labelKey}`) }}</b>
  </div>
</template>

<script>
  import { openTriviaDatabaseCategories } from '@/helpers/funteambidding/initials';

  export default {
    name: 'FunTeamBiddingCategorySelected',
    props: {
      activeCategory: {
        type: String,
        default: ''
      }
    },
    computed: {
      labelKey() {
        return openTriviaDatabaseCategories.find(category => category.id === this.activeCategory)
          ?.label;
      }
    }
  };
</script>

<style scoped lang="scss"></style>
