<template>
  <div class="flex items-center funteambidding__instruction-guy">
    <div
      class="funteambidding__instruction-guy__box"
      :class="{
        'funteambidding__instruction-guy__box--hidden': !instructionsOpen
      }"
    >
      {{
        $t(`funTeamBidding.instructions.guy.${phase}`, {
          value: seconds
        })
      }}
    </div>
    <div
      class="funteambidding__instruction-guy__hide"
      :class="{
        'funteambidding__instruction-guy__hide--hidden': !instructionsOpen
      }"
      @click="instructionsOpen = !instructionsOpen"
    >
      {{ instructionsOpen ? 'X' : $t(`common.instructions`) }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FunTeamBiddingInstructionGuy',
    props: {
      phase: {
        type: String,
        default: ''
      },
      seconds: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        instructionsOpen: true
      };
    }
  };
</script>

<style scoped lang="scss">
  .funteambidding__instruction-guy {
    position: relative;
    width: 0;
    height: 0;

    &__box {
      padding: 16px 32px;
      background: white;
      box-shadow: global-vars(ui-default-box-shadow--dark);
      @include font(12px, 600, 20px);
      width: 350px;
      border-radius: 24px 16px 0 24px;
      margin-right: global-vars(ui-default-measure);
      position: absolute;
      right: global-vars(ui-default-measure4x);
      bottom: global-vars(ui-default-measure2x);
      white-space: pre-line;

      @include mobile() {
        width: 250px;
        padding: 8px 12px;
      }

      &--hidden {
        position: relative;
        left: 100%;
        bottom: 0;
      }
    }

    &__hide {
      position: absolute;
      right: 4px;
      bottom: 4px;
      background: white;
      border-radius: global-vars(ui-default-measure3x);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: global-vars(ui-default-box-shadow);
      cursor: pointer;
      @include font(14px, 600, 20px);

      &--hidden {
        height: auto;
        width: auto;
        padding: 4px 8px;
      }
    }
  }
</style>
