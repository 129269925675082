
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import FunTeamBiddingPlayer from '@/components/funteambidding/FunTeamBiddingPlayer.vue';
  import useFirebaseFunTeamBidding from '@/composables/use-firebase-funteambidding';
  import {
    FunTeamBiddingPlayer as PlayerInterface,
    FunTeamBiddingTeam
  } from '@/interfaces/funteambidding';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import { useConfirm } from 'primevue/useconfirm';

  export default {
    name: 'FunTeamBiddingTeams',
    components: {
      FunTeamBiddingPlayer,
      BaseButtonWithSpinner
    },
    props: {
      phase: {
        type: String,
        default: ''
      },
      teams: {
        type: Array,
        default: () => []
      },
      players: {
        type: Array,
        default: () => []
      },
      small: {
        type: Boolean,
        default: false
      },
      avatarsPack: {
        type: String,
        default: 'mixed'
      },
      gameCreator: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props: any) {
      const {
        joinTeam,
        leaveTeam,
        currentPlayer,
        changeTeamName,
        addNewTeam,
        deletePlayer,
        changeCaptain
      } = useFirebaseFunTeamBidding();

      const { getCurrentUser } = useFirebase();
      const user = getCurrentUser();
      const { t } = useI18n();
      const { warningToast, infoToast } = useToast();
      const confirm = useConfirm();

      const activeTeamId = ref('');
      const newTeamNameLoading = ref(false);
      const showNewTeamNameModal = ref(false);
      const newTeamName = ref('');

      const openNewTeamModal = (id: string) => {
        showNewTeamNameModal.value = true;
        activeTeamId.value = id;
      };

      const handleChangeNewTeamName = async () => {
        if (!newTeamName.value) {
          warningToast(t('funTeamBidding.teams.emptyName'));
          return;
        }

        if (props.teams.some((t: FunTeamBiddingTeam) => t.name === newTeamName.value)) {
          warningToast(t('funTeamBidding.teams.nameTaken'));
          return;
        }

        newTeamNameLoading.value = true;
        await changeTeamName(props.teams, activeTeamId.value, newTeamName.value);
        newTeamNameLoading.value = false;
        showNewTeamNameModal.value = false;
      };

      const isPlayerInAnyTeam = computed(() => {
        const playersFromAllTeams = props.teams.flatMap((t: FunTeamBiddingTeam) => t.players);

        return playersFromAllTeams.some((player: PlayerInterface) => player.id === user?.uid);
      });

      const loggedInUserIsAPlayer = computed(() => {
        return props.players.some((player: PlayerInterface) => player.id === user?.uid);
      });

      const showJoinTeam = computed(() => {
        if (!loggedInUserIsAPlayer.value) {
          return false;
        } else if (!isPlayerInAnyTeam.value) {
          return true;
        }

        return false;
      });

      const isPlayerInSpecificTeam = (teamId: string) => {
        const team = props.teams.find((t: FunTeamBiddingTeam) => t.id === teamId);
        return team?.players.some(
          (player: PlayerInterface) => player.id === currentPlayer.value?.id
        );
      };

      const handleDeletePlayer = (
        playerId: string,
        teams: FunTeamBiddingTeam[],
        teamId: string
      ) => {
        confirm.require({
          header: t('common.areYouSure'),
          message: '',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            deletePlayer(playerId, teams, teamId);
            infoToast(t('common.playerRemoved'));
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      };

      return {
        joinTeam,
        leaveTeam,
        currentPlayer,
        isPlayerInAnyTeam,
        isPlayerInSpecificTeam,
        changeTeamName,
        newTeamNameLoading,
        showNewTeamNameModal,
        newTeamName,
        showJoinTeam,
        addNewTeam,
        changeCaptain,
        openNewTeamModal,
        handleDeletePlayer,
        handleChangeNewTeamName
      };
    }
  };
