
  import { defineComponent, onMounted, ref, Ref, computed, watch } from 'vue';

  import FunTeamBiddingJoinGame from '@/components/funteambidding/FunTeamBiddingJoinGame.vue';
  import FunTeamBiddingPhaseSelectingCategory from '@/components/funteambidding/phases/FunTeamBiddingPhaseSelectingCategory.vue';
  import FunTeamBiddingPhaseBidding from '@/components/funteambidding/phases/FunTeamBiddingPhaseBidding.vue';
  import FunTeamBiddingPhaseAnswer from '@/components/funteambidding/phases/FunTeamBiddingPhaseAnswer.vue';
  import FunTeamBiddingLeftNavigation from '@/components/funteambidding/FunTeamBiddingLeftNavigation.vue';
  import FunTeamBiddingPhaseNav from '@/components/funteambidding/phases/FunTeamBiddingPhaseNav.vue';
  import FunTeamBiddingInstructionGuy from '@/components/funteambidding/FunTeamBiddingInstructionGuy.vue';
  import FunTeamBiddingGameEnded from '@/components/funteambidding/FunTeamBiddingGameEnded.vue';
  import PeopleStillJoining from '@/components/PeopleStillJoining.vue';

  import LogoSpinner from '@/components/common/LogoSpinner.vue';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import IconAttribute from '@/components/common/IconAttribute.vue';
  import SoundMuting from '@/components/SoundMuting.vue';
  import AddAdmin from '@/components/AddAdmin.vue';
  import GameLogout from '@/components/GameLogout.vue';
  import PhaseWelcome from '@/components/common/PhaseWelcome.vue';

  import useFirebaseFunTeamBidding from '@/composables/use-firebase-funteambidding';
  import { useRouter } from 'vue-router';
  import useToast from '@/composables/use-toast';
  import { FUN_TEAM_BIDDING_PHASES } from '@/helpers/funteambidding/initials';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { getMoneySound, sellTeamSound } from '@/composables/use-audio';
  import { FunTeamBiddingTeam } from '@/interfaces/funteambidding';

  export default defineComponent({
    name: 'FunTeamBiddingGame',
    components: {
      GameLogout,
      IconAttribute,
      LanguageSelection,
      AddAdmin,
      LogoSpinner,
      SoundMuting,
      FunTeamBiddingJoinGame,
      FunTeamBiddingPhaseSelectingCategory,
      FunTeamBiddingPhaseBidding,
      FunTeamBiddingPhaseAnswer,
      FunTeamBiddingLeftNavigation,
      FunTeamBiddingPhaseNav,
      FunTeamBiddingInstructionGuy,
      PeopleStillJoining,
      FunTeamBiddingGameEnded
    },
    data() {
      return {
        optionsVisible: false
      };
    },
    setup() {
      const gameLoading: Ref = ref(false);
      const joinLoading: Ref = ref(false);

      const { currentRoute } = useRouter();
      const store = useStore();
      const { successToast, infoToast } = useToast();
      const { t, locale } = useI18n();

      const {
        getCurrentCompany,
        getOneFunTeamBiddingGameAndListen,
        showJoinGame,
        joinGame,
        showStartGameButton,
        startGame,
        cancelGame,
        jackpot,
        currentPlayer,
        currentUserIsCaptain,
        addAdmin
      } = useFirebaseFunTeamBidding();

      const instructions = computed(() => [
        t('funTeamBidding.shortInstructions.0'),
        t('funTeamBidding.shortInstructions.1'),
        t('funTeamBidding.shortInstructions.2'),
        t('funTeamBidding.shortInstructions.3')
      ]);

      const funTeamBiddingPhase = computed(() => {
        switch (store.state.currentFunTeamBiddingGame.phase) {
          case 'WELCOME':
            return PhaseWelcome;
          case FUN_TEAM_BIDDING_PHASES.SELECTING_CATEGORY.value:
            return FunTeamBiddingPhaseSelectingCategory;
          case FUN_TEAM_BIDDING_PHASES.BIDDING.value:
            return FunTeamBiddingPhaseBidding;
          case FUN_TEAM_BIDDING_PHASES.ANSWER.value:
            return FunTeamBiddingPhaseAnswer;
          default:
            return PhaseWelcome;
        }
      });

      const currentGame = computed(() => {
        return store.state.currentFunTeamBiddingGame;
      });

      onMounted(async () => {
        gameLoading.value = true;

        await getCurrentCompany(currentRoute.value.params.companyId);

        // @ts-ignore
        await getOneFunTeamBiddingGameAndListen(String(currentRoute.value.params.gameId));
        locale.value = store.state.company.signupLanguage || 'en';
        gameLoading.value = false;
      });

      const copy = (): void => {
        // @ts-ignore
        navigator.clipboard.writeText(
          `https://${window.location.hostname}/funteambidding/${currentRoute.value.params.companyId}/${currentRoute.value.params.gameId}`
        );
        successToast(t('common.copiedToClipboard'));
      };

      watch(
        () => currentGame.value.answered,
        newVal => {
          if (currentGame.value.phase === FUN_TEAM_BIDDING_PHASES.ANSWER.value && newVal) {
            if (currentGame.value.answerValid) {
              if (store.state.soundOn) {
                getMoneySound.play();
              }

              successToast(
                t('funTeamBidding.goodAnswer', {
                  name: currentGame.value.answeringTeamName,
                  jackpot: jackpot.value
                })
              );
            } else {
              if (store.state.soundOn) {
                sellTeamSound.play();
              }
            }
          }
        }
      );

      const handleAddAdmin = (id: string) => {
        addAdmin(id);
        successToast(t('common.adminChanged'));
      };

      const handleJoinGame = async (data: any) => {
        joinLoading.value = true;
        await joinGame(data);
        joinLoading.value = false;
      };

      watch(
        () => currentGame.value.noOfQuestionsLeft,
        newVal => {
          if (newVal === 1) {
            if (store.state.soundOn) {
              const msg = new SpeechSynthesisUtterance(t('common.lastRound'));
              msg.lang = locale.value === 'pl' ? 'pl-PL' : 'en-GB';

              window.speechSynthesis.speak(msg);
            }
          }
        }
      );

      watch(
        () => currentGame.value.vaBanqueTeam,
        newVal => {
          if (newVal) {
            infoToast(
              t('funTeamBidding.playedVaBanque', {
                name: currentGame.value.teams.find((t: FunTeamBiddingTeam) => t.id === newVal).name
              })
            );
          }
        }
      );

      return {
        currentGame,
        showJoinGame,
        gameLoading,
        showStartGameButton,
        startGame,
        handleJoinGame,
        cancelGame,
        copy,
        handleAddAdmin,
        funTeamBiddingPhase,
        FUN_TEAM_BIDDING_PHASES,
        instructions,
        currentPlayer,
        joinLoading,
        currentUserIsCaptain
      };
    }
  });
