
  import { ref, computed, defineComponent, onMounted, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import {
    emptyFunTeamBiddingSettings,
    openTriviaDatabaseCategories
  } from '@/helpers/funteambidding/initials';
  import {
    emptyFunTeamBiddingPlayer,
    emptyFunTeamBiddingGame
  } from '@/helpers/funteambidding/initials';
  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import { FunTeamBiddingCategory } from '@/interfaces/funteambidding';
  import { useI18n } from 'vue-i18n';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'FunTeamBiddingNewGameWithoutRegister',
    components: { LanguageSelection, LogoSpinner },
    setup() {
      const { getCurrentUser, addAlertThatNewGameStarted } = useFirebase();
      const user = getCurrentUser();

      const { push } = useRouter();
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const { t } = useI18n();

      const loading: Ref = ref(false);
      const gameId: Ref<string> = ref('');
      const newGameLoading: Ref<boolean> = ref(false);
      const name: Ref<string> = ref('');
      const nickname: Ref<string> = ref('');
      const strength: Ref<string> = ref('');
      const weakness: Ref<string> = ref('');
      const noOfQuestionsLeft: Ref<number> = ref(10);
      const selectedCategories: Ref<FunTeamBiddingCategory[]> = ref([
        ...openTriviaDatabaseCategories.filter(cat => cat.id !== 'CUSTOM')
      ]);
      const company: Ref = ref(store.state.company);

      const estimatedDuration: ComputedRef = computed(() => ({
        threeTen: `${noOfQuestionsLeft.value * 2 + 10} - ${noOfQuestionsLeft.value * 3 + 10}`,
        elevenTwenty: `${noOfQuestionsLeft.value * 3 + 10} - ${noOfQuestionsLeft.value * 4 + 10}`
      }));

      const categoriesToSelectFrom: ComputedRef = computed(() =>
        openTriviaDatabaseCategories.filter(cat => cat.id !== 'CUSTOM')
      );

      const checkIfCategorySelected = (id: string) => {
        return selectedCategories.value.some(cat => cat.id === id);
      };

      const handleToggleCategory = (category: FunTeamBiddingCategory) => {
        if (checkIfCategorySelected(category.id)) {
          selectedCategories.value = selectedCategories.value.filter(
            (c: FunTeamBiddingCategory) => c.id !== category.id
          );
        } else {
          selectedCategories.value = [...selectedCategories.value, category];
        }
      };

      const getNoRegisterCompany = async () => {
        company.value = await db
          .doc(`companies/${process.env.VUE_APP_FREE_COMPANY}`)
          .get()
          .then(doc => ({ id: doc.id, ...doc.data() }));
      };

      onMounted(async () => {
        loading.value = true;

        try {
          await getNoRegisterCompany();
        } catch (e) {
          errorToast(e);
        } finally {
          loading.value = false;
        }
      });

      const createNewGame = async (game: any) => {
        delete game.id;

        return await db
          .collection(`companies/${company.value.id}/funTeamBiddingGames`)
          .add({
            ...game,
            createdOn: Date.now()
          })
          .then(docRef => {
            return docRef.get();
          })
          .then(doc => {
            return doc.id;
          });
      };

      const addGame = async (): Promise<any> => {
        if (!company.value.id) {
          errorToast(t('common.somethingWrong'));
          return;
        }

        if ([name.value, nickname.value, strength.value, weakness.value].some(v => v.length > 20)) {
          warningToast(t('warnings.cantBeLongerThan', { value: 20 }));
          return;
        }

        if (newGameLoading.value) {
          return;
        }

        newGameLoading.value = true;

        if (!user?.uid) {
          await firebase.auth().signInAnonymously();
        }

        const newUser = getCurrentUser();

        const playerData = {
          ...emptyFunTeamBiddingPlayer,
          name: name.value,
          nickname: nickname.value,
          strength: strength.value,
          weakness: weakness.value,
          email: newUser?.email || null,
          id: newUser?.uid,
          admin: true
        };

        const newDocument = {
          ...emptyFunTeamBiddingGame,
          categories: selectedCategories.value,
          noOfQuestionsLeft: Number(noOfQuestionsLeft.value),
          players: [playerData],
          gameCreator: playerData,
          avatarsPack: company.value.avatars.name,
          teams: emptyFunTeamBiddingGame.teams.map((team, index) => ({
            ...team,
            money: emptyFunTeamBiddingSettings.teamMoneyForStart,
            avatar: company.value.avatars.list[index]
          })),
          settings: {
            categories: selectedCategories.value,
            teamMoneyForStart: emptyFunTeamBiddingSettings.teamMoneyForStart,
            moneyTakenForStartOfBidding: emptyFunTeamBiddingSettings.moneyTakenForStartOfBidding,
            timeAfterBidInSeconds: emptyFunTeamBiddingSettings.timeAfterBidInSeconds,
            maxDebt: emptyFunTeamBiddingSettings.maxDebt
          },
          customQuestions: company.value.funTeamBiddingCustomQuestions
        };

        try {
          gameId.value = await createNewGame(newDocument);
          await addAlertThatNewGameStarted(
            'FunTeamBidding',
            company.value.id,
            company.value.name,
            gameId.value
          );
          successToast(t('success.gameCreated'));
          store.commit('toggleSound', true);
          newGameLoading.value = false;
          goToGame();
        } catch (e) {
          newGameLoading.value = false;
          errorToast(e);
        }
      };

      const goToGame = (): void => {
        push({
          name: 'funteambidding-game',
          params: { companyId: company.value.id, gameId: gameId.value }
        });
      };

      return {
        loading,
        name,
        user,
        newGameLoading,
        company,
        nickname,
        strength,
        weakness,
        estimatedDuration,
        categoriesToSelectFrom,
        noOfQuestionsLeft,
        selectedCategories,
        checkIfCategorySelected,
        addGame,
        handleToggleCategory
      };
    }
  });
