
  import { computed, ref, Ref, defineComponent, onMounted, onUnmounted, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { formatDistanceToNowStrict, isFuture } from 'date-fns';
  import { useStore } from 'vuex';

  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import FunTeamBiddingPhaseBiddingHighestTeam from '@/components/funteambidding/bidding/FunTeamBiddingPhaseBiddingHighestTeam.vue';
  import FunTeamBiddingPhaseBiddingMenu from '@/components/funteambidding/bidding/FunTeamBiddingPhaseBiddingMenu.vue';
  import FunTeamBiddingPhaseBiddingHistory from '@/components/funteambidding/bidding/FunTeamBiddingPhaseBiddingHistory.vue';
  import FunTeamBiddingCategorySelected from '@/components/funteambidding/category-selection/FunTeamBiddingCategorySelected.vue';
  import { FunTeamBiddingItem } from '@/interfaces/funteambidding';
  import useFirebaseFunTeamBidding from '@/composables/use-firebase-funteambidding';
  import useToast from '@/composables/use-toast';
  import { nextPlayerSound } from '@/composables/use-audio';
  import { openTriviaDatabaseCategories } from '@/helpers/funteambidding/initials';
  import { enGB, pl } from 'date-fns/locale';

  export default defineComponent({
    name: 'FunTeamBiddingPhaseBidding',
    components: {
      FunTeamBiddingPhaseBiddingHighestTeam,
      FunTeamBiddingPhaseBiddingMenu,
      FunTeamBiddingPhaseBiddingHistory,
      FunTeamBiddingCategorySelected,
      BaseButtonWithSpinner
    },
    props: {
      game: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props) {
      const store = useStore();

      const { warningToast, infoToast, successToast } = useToast();
      const { t, locale } = useI18n();

      const loading = ref(false);
      const bidLoading = ref(false);
      const loanLoading = ref(false);
      const repayLoading = ref(false);
      const timeLeft: Ref<string | number> = ref(0);
      const timeInterval: Ref<number | undefined> = ref(undefined);

      const {
        addBidding,
        sortedBiddingHistory,
        highestBidder,
        currentUserTeam,
        jackpot,
        currentUserIsCaptain,
        readyForQuestion,
        passFromBidding,
        handleTakeLoan,
        handleRepayToBank
      } = useFirebaseFunTeamBidding();

      const currentTeamHighestBid = computed(() => {
        return sortedBiddingHistory.value.find(
          (item: FunTeamBiddingItem) => item.teamName === currentUserTeam.value.name
        );
      });

      const showConfig = computed(() => {
        return {
          canPass: sortedBiddingHistory.value[0].teamName !== currentUserTeam.value.name,
          canPlayVaBanque:
            sortedBiddingHistory.value[0].value <
            currentUserTeam.value.money + currentTeamHighestBid.value.value
        };
      });

      const handleBid = async (bid: string, vaBanque = false) => {
        if (
          highestBidder?.value?.value >= Number(bid) ||
          currentUserTeam.value.money + currentTeamHighestBid.value.value - Number(bid) < 0
        ) {
          warningToast(t('funTeamBidding.bidding.warning'));
          return;
        }

        try {
          bidLoading.value = true;

          await addBidding(
            {
              teamName: currentUserTeam.value.name,
              teamAvatar: currentUserTeam.value.avatar,
              value: Number(bid)
            },
            vaBanque
          );
        } finally {
          bidLoading.value = false;
        }
      };

      const handleQuickBid = async (amount: number) => {
        await handleBid(sortedBiddingHistory.value[0].value + amount);
      };

      const handlePass = async () => {
        await passFromBidding(currentUserTeam.value.name);
      };

      const handleGoVaBanque = async () => {
        await handleBid(currentTeamHighestBid.value.value + currentUserTeam.value.money, true);
      };

      const handleLoan = async (loan: string) => {
        if (!loan) {
          return;
        }
        if (
          currentUserTeam.value.loan + Number(loan) >
          store.state.company.funTeamBiddingSettings.maxDebt
        ) {
          warningToast(
            t('funTeamBidding.bidding.cantHaveMoreDebt', {
              maxDebt: store.state.company.funTeamBiddingSettings.maxDebt
            })
          );
          return;
        }
        loanLoading.value = true;
        await handleTakeLoan(currentUserTeam.value, Number(loan));
        successToast(t('funTeamBidding.bidding.loanSuccess', { value: loan }));
        loanLoading.value = false;
      };

      const handleRepay = async (repay: string) => {
        if (!repay) {
          return;
        }
        if (Number(repay) > currentUserTeam.value.loan) {
          warningToast(t('funTeamBidding.bidding.cantRepayMore'));
          return;
        }
        repayLoading.value = true;
        await handleRepayToBank(currentUserTeam.value, Number(repay));
        successToast(t('funTeamBidding.bidding.repaySuccess', { value: repay }));
        repayLoading.value = false;
      };

      onMounted(() => {
        if (store.state.soundOn) {
          nextPlayerSound.play();
        }

        const labelKey = openTriviaDatabaseCategories.find(
          category => category.id === props.game.activeCategory
        )?.label;

        if (store.state.soundOn) {
          const msg = new SpeechSynthesisUtterance(
            t('funTeamBidding.bidding.chosenCategory', {
              category: t(`funTeamBidding.categories.${labelKey}`)
            })
          );
          msg.lang = locale.value === 'pl' ? 'pl-PL' : 'en-GB';

          window.speechSynthesis.speak(msg);
        }

        timeInterval.value = setInterval(() => calculateTimeLeft(), 1000);
      });

      onUnmounted(() => {
        clearInterval(timeInterval.value);
      });

      const calculateTimeLeft = () => {
        const endOffsetted = props.game.endOfBidding - store.state.timeOffset;

        if (!isFuture(endOffsetted)) {
          timeLeft.value = -1;
          clearInterval(timeInterval.value);

          if (store.state.soundOn) {
            const msg = new SpeechSynthesisUtterance(
              t('funTeamBidding.bidding.teamWonWillStart', {
                name: highestBidder.value.teamName
              })
            );
            msg.lang = locale.value === 'pl' ? 'pl-PL' : 'en-GB';

            window.speechSynthesis.speak(msg);
          }

          return;
        }

        timeLeft.value = formatDistanceToNowStrict(endOffsetted, {
          locale: locale.value === 'pl' ? pl : enGB
        });
      };

      const isCurrentPlayerInWinningTeam = computed(() => {
        return highestBidder.value.teamName === currentUserTeam.value.name;
      });

      const handleReadyForQuestion = async () => {
        loading.value = true;
        await readyForQuestion();
        loading.value = false;
      };

      watch(
        () => props.game.teamsPassedFromBidding,
        (newVal, oldVal) => {
          if (newVal.length > oldVal.length) {
            infoToast(
              t('funTeamBidding.bidding.resigned', {
                name: newVal[newVal.length - 1]
              })
            );
          }
        }
      );

      return {
        loading,
        currentUserIsCaptain,
        bidLoading,
        sortedBiddingHistory,
        highestBidder,
        jackpot,
        currentUserTeam,
        timeLeft,
        showConfig,
        isCurrentPlayerInWinningTeam,
        loanLoading,
        repayLoading,
        handleBid,
        handleLoan,
        handleReadyForQuestion,
        handleQuickBid,
        handlePass,
        handleGoVaBanque,
        handleRepay
      };
    }
  });
