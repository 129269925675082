<template>
  <GameEnded :is-admin="isAdmin">
    <template #podium>
      <div class="flex game-ended__teams">
        <div
          v-for="team in sortedResults.slice(0, 3)"
          :key="team.id"
          class="game-ended__team flex flex-column items-center justify-between mb2"
        >
          <div class="flex items-center">
            <div class="game-ended__team-name mr2">{{ team.name }}</div>
            <div class="flex flex-column items-center">
              <div class="game-ended__team-money">
                {{ team.money - team.loan }}
              </div>
            </div>
          </div>
          <div class="game-ended__team-players">
            <FunTeamBiddingPlayer
              v-for="player in team.players"
              :key="player.id"
              :player="player"
              :show-only-name="true"
            />
          </div>
        </div>
      </div>
    </template>
    <template #rest>
      <div class="flex justify-center game-ended__teams mt3">
        <div
          v-for="team in sortedResults.slice(3)"
          :key="team.id"
          class="game-ended__other-team flex flex-column items-center justify-between mb2"
        >
          <div class="flex items-center">
            <div class="game-ended__other-team-name mr2">
              {{ team.name }}
            </div>
            <div class="flex flex-column items-center">
              <div class="game-ended__other-team-money">
                {{ team.money - team.loan }}
              </div>
            </div>
          </div>
          <div class="game-ended__other-team-players">
            <FunTeamBiddingPlayer
              v-for="player in team.players"
              :key="player.id"
              :player="player"
              :show-only-name="true"
            />
          </div>
        </div>
      </div>
    </template>
  </GameEnded>
</template>

<script>
  import FunTeamBiddingPlayer from '@/components/funteambidding/FunTeamBiddingPlayer';
  import GameEnded from '@/components/common/GameEnded';

  export default {
    name: 'FunTeamBiddingGameEnded',
    components: {
      FunTeamBiddingPlayer,
      GameEnded
    },
    props: {
      results: {
        type: Array,
        default: () => []
      },
      isAdmin: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      sortedResults() {
        return [...this.results].sort((a, b) => b.money - b.loan - (a.money - a.loan));
      }
    }
  };
</script>

<style scoped lang="scss">
  .game-ended {
    &__teams {
      width: 1200px;

      @include md-down() {
        width: 750px;
      }

      @include mobile() {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }

    &__team {
      width: 340px;
      margin: 0 30px;
      position: relative;
      padding: global-vars(ui-default-measure3x);
      border-radius: global-vars(ui-default-measure2x);
      background: white;
      box-shadow: global-vars(ui-default-box-shadow--dark);

      @include md-down() {
        width: 230px;
        margin: 0 10px;
      }

      &:first-of-type {
        order: 2;
        top: -30px;

        @include mobile() {
          order: 1;
        }
      }
      &:nth-of-type(2) {
        order: 1;
        top: global-vars(ui-default-measure5x);

        @include mobile() {
          order: 2;
        }
      }
      &:nth-of-type(3) {
        order: 3;
        top: 120px;

        @include mobile() {
          order: 3;
        }
      }

      @include mobile() {
        top: 0 !important;
        margin-bottom: global-vars(ui-default-measure2x);
      }

      &-name,
      &-money {
        padding: 8px 16px;
        border-radius: global-vars(ui-default-measure2x);
        color: color(primary);
      }

      &-name {
        @include font(18px, 600);
      }

      &-money {
        @include font(24px, 600);
      }

      &-debt {
        @include font(12px, 600);
      }

      &-players {
        color: color(primary);
        padding: 24px 0;
        width: 100%;
      }
    }

    &__other-team {
      width: 250px;
      margin: 0 30px;
      position: relative;
      padding: global-vars(ui-default-measure3x);
      border-radius: global-vars(ui-default-measure2x);
      background: white;
      box-shadow: global-vars(ui-default-box-shadow--dark);

      @include md-down() {
        width: 180px;
        margin: 0 10px;
      }

      @include mobile() {
        margin: 10px;
      }

      &-name,
      &-money {
        padding: 8px 16px;
        border-radius: global-vars(ui-default-measure2x);
      }

      &-name {
        @include font(14px, 600);
      }

      &-money {
        @include font(16px, 600);
      }

      &-debt {
        @include font(12px, 600);
      }

      &-players {
        padding: 8px 0;
        width: 100%;
      }
    }
  }
</style>
