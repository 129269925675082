<template>
  <div class="flex items-center funteambidding__player">
    <div
      v-if="player.isCaptain"
      class="flex items-center justify-center funteambidding__player-indicator"
    >
      <BaseTooltipWrapper :tooltip="$t('funTeamBidding.player.captain')">
        <span class="captain">
          {{ $t('funTeamBidding.player.captain').split('')[0] }}
        </span>
      </BaseTooltipWrapper>
    </div>
    <div
      class="flex items-center justify-center funteambidding__player-make-captain-indicator"
      v-if="showMakeCaptain"
      @click="$emit('change', player.id)"
    >
      <span class="make-captain">
        {{ $t('funTeamBidding.player.makeCaptain') }}
      </span>
    </div>
    <div
      class="flex items-center justify-between funteambidding__player-info"
      :class="{ 'not-captain': !player.isCaptain && !showMakeCaptain }"
    >
      <div class="funteambidding__player-name-wrapper">
        <div class="flex items-center">
          <div class="funteambidding__player-name">
            {{ player.name }}
            <Chip v-if="player.admin" class="small">
              {{ player.admin ? `${$t('players.admin')}` : '' }}
            </Chip>
            <Chip v-if="player.id === currentPlayer.id" class="small primary ml1">
              {{ player.id === currentPlayer.id ? `${$t('players.you')}` : '' }}
            </Chip>
          </div>
          <div v-if="player.nickname" class="funteambidding__player-nickname">
            {{ player.nickname }}
          </div>
        </div>
      </div>
      <div v-if="!showOnlyName" class="funteambidding__player-attributes">
        <div
          v-if="player.strength"
          class="flex items-center funteambidding__player-attribute funteambidding__player-strength"
        >
          <img :src="require(`@/assets/images/plus-sign.svg`)" alt="Thumbs up" />
          {{ player.strength }}
        </div>
        <div
          v-if="player.weakness"
          class="flex items-center funteambidding__player-attribute funteambidding__player-weakness"
        >
          <img :src="require(`@/assets/images/minus-sign.svg`)" alt="Thumbs down" />
          {{ player.weakness }}
        </div>
      </div>
    </div>
    <div
      v-if="showDeletePlayer"
      class="flex items-center justify-center funteambidding__player-remove"
    >
      <BaseTooltipWrapper :tooltip="$t('common.removePlayer')">
        <i class="pi pi-user-minus" @click="$emit('delete', player.id)" />
      </BaseTooltipWrapper>
    </div>
  </div>
</template>

<script>
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper';

  export default {
    name: 'FunTeamBiddingPlayer',
    components: { BaseTooltipWrapper },
    props: {
      player: {
        type: Object,
        default: () => ({})
      },
      currentPlayer: {
        type: Object,
        default: () => ({})
      },
      showOnlyName: {
        type: Boolean,
        default: false
      },
      currentPlayerInTheTeam: {
        type: Object,
        default: null
      },
      showDeletePlayer: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      showMakeCaptain() {
        if (this.currentPlayer?.admin && !this.player.isCaptain) {
          return true;
        }

        return (
          this.currentPlayerInTheTeam &&
          this.currentPlayerInTheTeam.isCaptain &&
          this.currentPlayerInTheTeam.id !== this.player.id
        );
      }
    },
    emits: ['change']
  };
</script>

<style scoped lang="scss">
  .funteambidding__player {
    margin: 4px 0;
    max-width: 300px;

    &:not(:last-of-type) {
      border-bottom: 1px solid color(gray-background);
    }

    &-info {
      padding-left: global-vars(ui-default-measure);
      width: 100%;

      &.not-captain {
        padding-left: 44px;
      }
    }

    &-indicator {
      height: global-vars(ui-default-measure4x);
      width: global-vars(ui-default-measure5x);
      border-radius: 50%;

      .captain {
        @include font(18px, 600);
        color: black;
      }
    }

    &-remove {
      cursor: pointer;
    }

    &-make-captain-indicator {
      height: global-vars(ui-default-measure4x);
      width: global-vars(ui-default-measure5x);
      border-radius: 6px;
      cursor: pointer;

      .make-captain {
        @include font(8px, 600);
        text-align: center;
        color: black;
        padding: 2px;
        border: 1px solid black;
      }
    }

    &-name-wrapper {
      margin-right: 10px;
    }

    &-name {
      @include font(14px, 600);
      margin-bottom: 4px;
    }

    &-position {
      @include font(11px);
    }

    &-attribute {
      margin: 4px 0;

      img {
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }
    }

    &-nickname {
      @include font(11px, 600);
      background: color(gray-background);
      color: color(primary);
      margin-left: global-vars(ui-default-measure);
      padding: 6px 8px;
      border-radius: 10px;
    }

    &-strength {
      @include font(10px);
    }

    &-weakness {
      @include font(10px);
    }
  }
</style>
