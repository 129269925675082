<template>
  <div class="flex flex-wrap">
    <div
      class="category m1"
      v-for="(category, index) in game.categories"
      :key="category"
      :class="{
        'category--active': activeCategory === index,
        'category--selected': activeCategory === index && categorySelected
      }"
    >
      {{ $t(`funTeamBidding.categories.${category?.label}`) }}
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue';

  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import useToast from '@/composables/use-toast';

  export default {
    name: 'FunTeamBiddingPhaseSelectingCategory',
    props: {
      game: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props) {
      const { t, locale } = useI18n();
      const store = useStore();
      const { successToast } = useToast();

      const activeCategory = ref(0);
      const categorySelected = ref(false);
      const interval = ref(null);
      const check = ref(0);

      const setActive = () => {
        activeCategory.value = Math.floor(
          Math.random() * store.state.currentFunTeamBiddingGame.categories.length
        );
        check.value += 1;
        if (check.value === 10) {
          clearInterval(interval.value);

          activeCategory.value = store.state.currentFunTeamBiddingGame.categories.findIndex(
            category => category.id === props.game.activeCategory
          );

          categorySelected.value = true;

          successToast(
            `${t('funTeamBidding.selectedCategory')} - ${t(
              `funTeamBidding.categories.${
                store.state.currentFunTeamBiddingGame.categories[activeCategory.value]?.label
              }`
            )}. ${t('funTeamBidding.nextPhase')}`
          );
        }
      };

      const speak = message => {
        const msg = new SpeechSynthesisUtterance(message);
        msg.lang = locale.value === 'en' ? 'en-GB' : 'pl-PL';

        window.speechSynthesis.speak(msg);
      };

      onMounted(() => {
        if (store.state.soundOn) {
          setTimeout(() => {
            speak(t('common.nextRound'));
          }, 500);
        }

        interval.value = setInterval(() => setActive(), 200);
      });

      return {
        activeCategory,
        categorySelected
      };
    }
  };
</script>

<style scoped lang="scss">
  .category {
    padding: global-vars(ui-default-measure3x);
    font-weight: 700;
    color: white;
    background: color(accent);
    border: 3px solid white;
    box-shadow: global-vars(ui-default-box-shadow--dark);
    border-radius: global-vars(ui-default-measure2x);

    @include lg-down() {
      padding: global-vars(ui-default-measure);
      border: 2px solid white;
      @include font(16px, 600, 24px);
    }

    @include mobile() {
      padding: 6px;
      border: 1px solid white;
      @include font(13px, 600, 19px);
    }

    &--active {
      color: color(primary);
      box-shadow: global-vars(ui-default-box-shadow--light);
      background: white;
      border: 3px solid transparent;

      @include lg-down() {
        border: 2px solid transparent;
      }

      @include mobile() {
        border: 1px solid transparent;
      }
    }

    &--selected {
      color: color(primary);
      box-shadow: global-vars(ui-default-box-shadow--light);
      background: white;
      border: 3px solid transparent;

      @include lg-down() {
        border: 2px solid transparent;
      }

      @include mobile() {
        border: 1px solid transparent;
      }
    }
  }
</style>
